<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື່ ແລະ ນາມສະກຸນ :"
              prop="user_full_name"
              v-model="form.user_full_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="ທີ່ຢູ່ :" prop="user_address" v-model="form.user_address" />
        </CCol>
        <CCol sm="6">
          <CInput label="ເບີໂທລະສັບ :" prop="user_tel" v-model="form.user_tel" />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື້ເຂົ້າລະບົບ :"
              prop="username"
              v-model="form.username"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ລະຫັດຜ່ານ :"
              prop="password"
              v-model="form.password"
              type="password"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ກຸ່ມສິດເຂົ້າໃຊ້ງານ :</label>
              <v-select
                class="is-invalid"
                prop="user_group"
                v-model="form.user_group"
                :options="groupList"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { encrypt } from "@/libs/crypto";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "UserFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    staffInfo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        user_full_name: "",
        user_address: "",
        user_tel: "",
        username: "",
        password: "",
        user_group: "",
      },
      groupList: ["administrator", "manage", "staff"],
    };
  },
  methods: {
    handleSubmit() {
      // encrypt password
      let hash_password = encrypt(this.form.password);
      this.$emit("on_success", {
        user_full_name: this.form.user_full_name,
        user_address: this.form.user_address,
        user_tel: this.form.user_tel,
        username: this.form.username,
        password: hash_password,
        user_group: this.form.user_group,
      });
      this.form.user_full_name = "";
      this.form.user_address = "";
      this.form.user_tel = "";
      this.form.username = "";
      this.form.password = "";
      this.form.user_group = "";
    },
  },
};
</script>