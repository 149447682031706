<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື່ ແລະ ນາມສະກຸນ :"
              prop="user_full_name"
              v-model="form.user_full_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
            <CInput
              label="ທີ່ຢູ່ :"
              prop="user_address"
              v-model="form.user_address"
            />
        </CCol>
        <CCol sm="6">
            <CInput
              label="ເບີໂທລະສັບ :"
              prop="user_tel"
              v-model="form.user_tel"
            />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຊື້ເຂົ້າລະບົບ :"
              prop="username"
              v-model="form.username"
              disabled
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
            <CInput
              label="ລະຫັດຜ່ານ :"
              prop="password"
              v-model="form.password"
              type="password"
              :disabled="form.username == 'administrator'"
            />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ກຸ່ມສິດເຂົ້າໃຊ້ງານ :</label>
              <v-select
                class="is-invalid"
                prop="user_group"
                v-model="form.user_group"
                :options="groupList"
                :disabled="form.username == 'administrator'"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ສະຖານະ :</label>
              <v-select
                class="is-invalid"
                prop="user_status"
                v-model="form.user_status"
                :options="statusList"
                :reduce="code => code.code"
                label="message"
                :disabled="form.username == 'administrator'"

              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>

        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="warning"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { encrypt } from "@/libs/crypto";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "UserFormUpdate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    staffInfo: {
      type: Array,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        user_full_name: this.userInfo.user_full_name,
        user_address: this.userInfo.user_address,
        user_tel: this.userInfo.user_tel,
        username: this.userInfo.username,
        password: "",
        user_group: this.userInfo.user_group,
        user_status: this.userInfo.user_status,
      };
    },
  },
  data() {
    return {
      statusList: [{code:"1", message:"active"},{code:"0", message:"block"}],
      groupList: ["administrator", "manage", "staff"],
    };
  },
  methods: {
    handleSubmit() {
      // encrypt password
      let hash_password = null;
      if (this.form.password !== "") {
        hash_password = encrypt(this.form.password);
      }

      this.$emit("on_success", {
        id: this.userInfo.user_id,
        user_full_name: this.form.user_full_name,
        user_address: this.form.user_address,
        user_tel: this.form.user_tel,
        password: hash_password,
        user_group: this.form.user_group,
        user_status: this.form.user_status,
        staff_id: this.form.staff_id,
      });
    },
  },
};
</script>