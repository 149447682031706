<template>
  <div v-if="userGroup == 'administrator'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນຜູ້ເຂົ້າໃຊ້ງານລະບົບ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມຜູ້ເຂົ້າໃຊ້ງານ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #user_status="{item}">
                <td>
                  <CBadge :color="getBadge(item.user_status)">
                    <strong v-if="item.user_status==0">Blocked</strong>
                    <strong v-if="item.user_status==1">Active</strong>

                  </CBadge>
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem v-on:click="onClickUpdate(row.item)">ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- Modal Create New Branch -->
    <CModal title="ຟອມເພີ້ມຜູ້ເຂົ້າໃຊ້ງານລະບົບ" size="lg" :show.sync="modalCreate" :closeOnBackdrop="false">
      <userFormCreate :staffInfo="staffData" @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <!-- Modal Update Branch -->
    <CModal
      title="ຟອມແກ້ໄຂຂໍ້ມູນຜູ້ເຂົ້າໃຊ້ງານລະບົບ"
      size="lg"
      :show.sync="modalUpdate"
      :closeOnBackdrop="false"
    >
      <userFormUpdate :staffInfo="staffData" :userInfo="userData" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  getUserAPI,
  createUserAPI,
  updateUserAPI,
  updatePasswordAPI,
  deleteUserAPI,
} from "@/api/user";
import userFormCreate from "./components/UserFormCreate";
import userFormUpdate from "./components/UserFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "User",
  components: {
    userFormCreate,
    userFormUpdate
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "user_id", label: "ລະຫັດ"},
        { key: "username", label: "ຊື່ເຂົ້າໃຊ້ງານ"},
        {  key: "user_status", label: "ສະຖານະ" },
        { key: "user_group",  label: "ກຸ່ມເຂົ້າໃຊ້ງານ" },
        { key: "user_full_name", label: "ຊື່ ແລະ ນາມສະກູນ" },
        { key: "user_tel", label: "ເບີໂທລະສັບ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      userData: {},
      staffData: []
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    getBadge(user_status) {
      return user_status === "0"
        ? "danger"
        : user_status === "1"
        ? "success"
        : "success";
    },
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getUserAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },

    // Create New
    onSubmitCreate(credentials) {
      createUserAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },

    // Click Update button
    onClickUpdate(item) {
      this.userData = item;
      this.modalUpdate = true;
    },
    // function update
    onSubmitUpdate(credentials) {
      updateUserAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },

    // Click delete button
    onClickDelete(item) {
      let onOk = () => {
        deleteUserAPI(item.user_id)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>